<template>
  <div>
    <div class="navbar navbar-fixed-top">
      <button class="closed" id="newMenuButton" onclick="onMenuButtonClick()">
        <i class="material-icons">menu</i>
        <span>選單</span>
      </button>
      <div class="container">
        <button
          type="button"
          class="rwd-navbar-toggle rwd-collapse"
          style="display: none"
        >
          <span class="label">選單</span><span class="navbar-icon-bar"></span>
        </button>
      </div>
    </div>
    <div id="rwd-side" class="rwd-side rwd-collapse">
      <div class="inner">
        <ul class="topnav">
          <li class="home">
            <a
              @click="
                $router.push({
                  name: 'Home',
                })
              "
              >回首頁</a
            >
          </li>
        </ul>
        <div class="nav">
          <ul>
            <li class="news first">
              <a
                @click="
                  $router.push({
                    name: 'TopNews',
                  })
                "
              >
                <span>最新消息</span></a
              >
            </li>
            <li
              class="about-us has-subnav"
              :class="aboutUsOpened ? 'opened' : ''"
            >
              <button
                type="button"
                class="toggle"
                @click="aboutUsOpened = !aboutUsOpened"
              ></button
              ><a><span>關於我們</span></a>
              <ul class="subnav">
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'BrandStory',
                        })
                      "
                      ><span>品牌故事/創辦人介紹</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'TianxunGroup',
                        })
                      "
                      ><span>天訊集團</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'ShanghaiLouyi',
                        })
                      "
                      ><span>上海樓易</span></a
                    >
                  </div>
                </li>
              </ul>
            </li>
            <li
              class="seasonal has-subnav"
              :class="seasonalOpened ? 'opened' : ''"
            >
              <button
                type="button"
                class="toggle"
                @click="seasonalOpened = !seasonalOpened"
              ></button
              ><a
                @click="
                  $router.push({
                    name: 'Qi0',
                  })
                "
                ><span>五運六氣</span></a
              >
              <ul class="subnav">
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi1',
                        })
                      "
                      ><span>初之氣</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi2',
                        })
                      "
                      ><span>二之氣</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi3',
                        })
                      "
                      ><span>三之氣</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi4',
                        })
                      "
                      ><span>四之氣</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi5',
                        })
                      "
                      ><span>五之氣</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi6',
                        })
                      "
                      ><span>六之氣</span></a
                    >
                  </div>
                </li>
              </ul>
            </li>
            <li class="featured-articles">
              <a
                @click="
                  $router.push({
                    name: 'Featureds',
                  })
                "
                ><span>精選文章</span></a
              >
            </li>
            <li class="wonderful-video">
              <a
                @click="
                  $router.push({
                    name: 'Videos',
                  })
                "
                ><span>精彩影片</span></a
              >
            </li>
          </ul>
        </div>
        <!-- <div class="product-search">
          <input
            type="text"
            class="product-keyword"
            value=""
            autocomplete="off"
          /><button
            type="submit"
            data-loading-text="Search.."
            class="btn-search"
          >
            <i class="fa fa-search"></i>
          </button>
        </div> -->
        <div class="side"></div>
      </div>
    </div>
    <div class="topnavbar">
      <div class="container">
        <div id="logo">
          <a
            @click="
              $router.push({
                name: 'Home',
              })
            "
          >
            <h1>
              <img
                id="photoLogo"
                src="/static.iyp.tw/40147/system/logo_BNuvZ.png?lg"
                alt="樓易健康科技股份有限公司"
              /><span>樓易健康科技股份有限公司</span>
            </h1>
          </a>
        </div>
        &nbsp;
        <!-- 避免將 logo 與 nav 併在一起 -->
        <nav id="nav">
          <ul>
            <li
              class="news first"
              :class="
                ['News', 'TopNews'].indexOf($route.name) >= 0 ? 'current' : ''
              "
            >
              <a
                @click="
                  $router.push({
                    name: 'TopNews',
                  })
                "
                ><span>最新消息</span></a
              >
            </li>
            <li
              class="about-us has-subnav"
              :class="
                ['BrandStory', 'TianxunGroup', 'ShanghaiLouyi'].indexOf(
                  $route.name
                ) >= 0
                  ? 'current'
                  : ''
              "
            >
              <a><span>關於我們</span></a>
              <ul class="subnav">
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'BrandStory',
                        })
                      "
                      ><span>品牌故事/創辦人介紹</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'TianxunGroup',
                        })
                      "
                      ><span>天訊集團</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'ShanghaiLouyi',
                        })
                      "
                      ><span>上海樓易</span></a
                    >
                  </div>
                </li>
              </ul>
            </li>
            <li
              class="seasonal has-subnav"
              :class="
                $route.name.indexOf('Qi') >= 0
                  ? 'current'
                  : ''
              "
            >
              <a
                @click="
                  $router.push({
                    name: 'Qi0',
                  })
                "
                ><span>五運六氣</span></a
              >
              <ul class="subnav">
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi1',
                        })
                      "
                      ><span>初之氣</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi2',
                        })
                      "
                      ><span>二之氣</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi3',
                        })
                      "
                      ><span>三之氣</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi4',
                        })
                      "
                      ><span>四之氣</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi5',
                        })
                      "
                      ><span>五之氣</span></a
                    >
                  </div>
                </li>
                <li class="no-subnav">
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'Qi6',
                        })
                      "
                      ><span>六之氣</span></a
                    >
                  </div>
                </li>
              </ul>
            </li>
            <li
              class="featured-articles"
              :class="
                ['Featureds', 'FeaturedDetail'].indexOf($route.name) >= 0
                  ? 'current'
                  : ''
              "
            >
              <a
                @click="
                  $router.push({
                    name: 'Featureds',
                  })
                "
                ><span>精選文章</span></a
              >
            </li>
            <li
              class="wonderful-video"
              :class="
                ['Videos', 'VideoDetail'].indexOf(
                  $route.name
                ) >= 0
                  ? 'current'
                  : ''
              "
            >
              <a
                @click="
                  $router.push({
                    name: 'Videos',
                  })
                "
                ><span>精彩影片</span></a
              >
            </li>
            <!-- <li class="language has-subnav">
                <a href="/language.html"><span>Language / 語系</span></a>
                <ul class="subnav">
                  <li class="no-subnav">
                    <div>
                      <a href="/china.html"><span>英文</span></a>
                    </div>
                  </li>
                </ul>
              </li> -->
          </ul>
        </nav>
      </div>
    </div>
    <div id="banner" data-enable="0"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      aboutUsOpened: false,
      seasonalOpened: false,
    };
  },
};
</script>