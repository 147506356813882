import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
export default new Vuex.Store({
    getters: {
        articles: state => state.articles,
        pages:state=>state.pages
    },
    state() {
        return {
            articles: [],
            pages:[]
        }
    },
    mutations: {
         articles(state, items) {
            state.articles = items;
        },
         pages(state, items) {
            state.pages = items;
        },
    }
})