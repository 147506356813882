<template>
  <div>
    <h1>登入</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>