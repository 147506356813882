var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"rwd-side rwd-collapse",attrs:{"id":"rwd-side"}},[_c('div',{staticClass:"inner"},[_c('ul',{staticClass:"topnav"},[_c('li',{staticClass:"home"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'Home',
              })}}},[_vm._v("回首頁")])])]),_vm._v(" "),_c('div',{staticClass:"nav"},[_c('ul',[_c('li',{staticClass:"news first"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'TopNews',
                })}}},[_c('span',[_vm._v("最新消息")])])]),_vm._v(" "),_c('li',{staticClass:"about-us has-subnav",class:_vm.aboutUsOpened ? 'opened' : ''},[_c('button',{staticClass:"toggle",attrs:{"type":"button"},on:{"click":function($event){_vm.aboutUsOpened = !_vm.aboutUsOpened}}}),_vm._m(1),_vm._v(" "),_c('ul',{staticClass:"subnav"},[_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'BrandStory',
                      })}}},[_c('span',[_vm._v("品牌故事/創辦人介紹")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'TianxunGroup',
                      })}}},[_c('span',[_vm._v("天訊集團")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'ShanghaiLouyi',
                      })}}},[_c('span',[_vm._v("上海樓易")])])])])])]),_vm._v(" "),_c('li',{staticClass:"seasonal has-subnav",class:_vm.seasonalOpened ? 'opened' : ''},[_c('button',{staticClass:"toggle",attrs:{"type":"button"},on:{"click":function($event){_vm.seasonalOpened = !_vm.seasonalOpened}}}),_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'Qi0',
                })}}},[_c('span',[_vm._v("五運六氣")])]),_vm._v(" "),_c('ul',{staticClass:"subnav"},[_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi1',
                      })}}},[_c('span',[_vm._v("初之氣")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi2',
                      })}}},[_c('span',[_vm._v("二之氣")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi3',
                      })}}},[_c('span',[_vm._v("三之氣")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi4',
                      })}}},[_c('span',[_vm._v("四之氣")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi5',
                      })}}},[_c('span',[_vm._v("五之氣")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi6',
                      })}}},[_c('span',[_vm._v("六之氣")])])])])])]),_vm._v(" "),_c('li',{staticClass:"featured-articles"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'Featureds',
                })}}},[_c('span',[_vm._v("精選文章")])])]),_vm._v(" "),_c('li',{staticClass:"wonderful-video"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'Videos',
                })}}},[_c('span',[_vm._v("精彩影片")])])])])]),_vm._v(" "),_c('div',{staticClass:"side"})])]),_vm._v(" "),_c('div',{staticClass:"topnavbar"},[_c('div',{staticClass:"container"},[_c('div',{attrs:{"id":"logo"}},[_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: 'Home',
            })}}},[_vm._m(2)])]),_vm._v("\n       \n      "),_vm._v(" "),_c('nav',{attrs:{"id":"nav"}},[_c('ul',[_c('li',{staticClass:"news first",class:['News', 'TopNews'].indexOf(_vm.$route.name) >= 0 ? 'current' : ''},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'TopNews',
                })}}},[_c('span',[_vm._v("最新消息")])])]),_vm._v(" "),_c('li',{staticClass:"about-us has-subnav",class:['BrandStory', 'TianxunGroup', 'ShanghaiLouyi'].indexOf(
                _vm.$route.name
              ) >= 0
                ? 'current'
                : ''},[_vm._m(3),_vm._v(" "),_c('ul',{staticClass:"subnav"},[_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'BrandStory',
                      })}}},[_c('span',[_vm._v("品牌故事/創辦人介紹")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'TianxunGroup',
                      })}}},[_c('span',[_vm._v("天訊集團")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'ShanghaiLouyi',
                      })}}},[_c('span',[_vm._v("上海樓易")])])])])])]),_vm._v(" "),_c('li',{staticClass:"seasonal has-subnav",class:_vm.$route.name.indexOf('Qi') >= 0
                ? 'current'
                : ''},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'Qi0',
                })}}},[_c('span',[_vm._v("五運六氣")])]),_vm._v(" "),_c('ul',{staticClass:"subnav"},[_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi1',
                      })}}},[_c('span',[_vm._v("初之氣")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi2',
                      })}}},[_c('span',[_vm._v("二之氣")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi3',
                      })}}},[_c('span',[_vm._v("三之氣")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi4',
                      })}}},[_c('span',[_vm._v("四之氣")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi5',
                      })}}},[_c('span',[_vm._v("五之氣")])])])]),_vm._v(" "),_c('li',{staticClass:"no-subnav"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                        name: 'Qi6',
                      })}}},[_c('span',[_vm._v("六之氣")])])])])])]),_vm._v(" "),_c('li',{staticClass:"featured-articles",class:['Featureds', 'FeaturedDetail'].indexOf(_vm.$route.name) >= 0
                ? 'current'
                : ''},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'Featureds',
                })}}},[_c('span',[_vm._v("精選文章")])])]),_vm._v(" "),_c('li',{staticClass:"wonderful-video",class:['Videos', 'VideoDetail'].indexOf(
                _vm.$route.name
              ) >= 0
                ? 'current'
                : ''},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                  name: 'Videos',
                })}}},[_c('span',[_vm._v("精彩影片")])])])])])])]),_vm._v(" "),_c('div',{attrs:{"id":"banner","data-enable":"0"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar navbar-fixed-top"},[_c('button',{staticClass:"closed",attrs:{"id":"newMenuButton","onclick":"onMenuButtonClick()"}},[_c('i',{staticClass:"material-icons"},[_vm._v("menu")]),_vm._v(" "),_c('span',[_vm._v("選單")])]),_vm._v(" "),_c('div',{staticClass:"container"},[_c('button',{staticClass:"rwd-navbar-toggle rwd-collapse",staticStyle:{"display":"none"},attrs:{"type":"button"}},[_c('span',{staticClass:"label"},[_vm._v("選單")]),_c('span',{staticClass:"navbar-icon-bar"})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',[_c('span',[_vm._v("關於我們")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('img',{attrs:{"id":"photoLogo","src":"/static.iyp.tw/40147/system/logo_BNuvZ.png?lg","alt":"樓易健康科技股份有限公司"}}),_c('span',[_vm._v("樓易健康科技股份有限公司")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',[_c('span',[_vm._v("關於我們")])])}]

export { render, staticRenderFns }