<script>
export default {
  props: {
    article: {
      type: Object,
      default() {
        return {
          title: "",
          img: "",
          content: "",
          description: "",
        };
      },
    },
    id: {
      type: String | Number,
      default: "",
    },
  },
  mounted() {
    if (!this.article.title) {
      this.setArticle();
    }
  },
  methods: {
    setArticle() {
      this.$store.state.articles.find((article) => {
        if (article.id == this.id) {
          Object.assign(this.article, article);
        }
        return article.id == this.id;
      });
    },
  },
  computed: {
    /**
     * 以category篩選文章
     */
    articles() {
      const categories = this.category.split(",");
      return this.$store.state.articles.filter((article) => {
        return categories.find(
          (category) =>
            article.category == category && article.type == this.type
        );
      });
    },
    moreArticles() {
      let maxCount = 8;
      maxCount =
        this.articles.length < maxCount ? this.articles.length : maxCount;
      let more = [];
      const index = this.articles.findIndex((article) => article.id == this.id);
      more = this.articles.slice(index + 1, index + 1 + maxCount);
      const realCount = more.length;
      if (realCount < maxCount) {
        more.push(...this.articles.slice(0, maxCount - realCount));
      }
      return more;
    },
  },
  data() {
    return {
      /**
       * category篩選, 可用逗號多選
       * type篩選
       */
      category: "",
      type: "",
    };
  },
  watch: {
    "$store.state.articles"(value) {
      this.setArticle();
    },
  },
};
</script>