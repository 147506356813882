<template>
  <a :title="article.title">
    <div class="img">
      <img
        :alt="article.title"
        v-lazy="article.preview_img"
        :id="article.id"
        class="photoSmall"
      />
    </div>

    <h3>{{ article.title }}</h3>
    <span class="desc">{{ getContent(article) }} </span>
  </a>
</template>
<script>
export default {
  props: {
    article: {
      type: Object,
      default: null,
    },
  },
  methods: {
    /**取得文章簡短描述(30字) */
    getContent(article) {
      // 移除文章內圖片, 避免轉換成dom時過度載入
      const contentWithoutSrc = article.content.replace(/src=['"].+['"]/g, "");
      const contentText = $(contentWithoutSrc).text();
      return contentText ? contentText.substr(0, 30) + "..." : "";
    },
  },
};
</script>