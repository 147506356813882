<template>
  <div id="main-wrap" class="main-container">
    <div class="fcol fcol-1">
      <div class="container">
        <div id="nicedit_0" class="edit-area">
          <p>
            <video
              width="100%"
              height="auto"
              style="background-size: cover; visibility: inherit; opacity: 1"
              muted=""
              playsinline=""
              autoplay="autoplay"
              class="resizelistener"
              loop="loop"
              preload="preload"
            >
              <source
                src="/static.iyp.tw/40147/files/e5af7596-22ae-4c27-a76b-82a10b48758d.mp4"
                type="video/mp4"
              />
            </video>
          </p>
        </div>
      </div>
    </div>
    <div class="fcol fcol-2">
      <div class="container">
        <div id="nicedit_1" class="edit-area">
          <div class="x2">
            <div class="row">
              <div
                v-for="(article, index) in topTwoArticles"
                :key="index"
                class="col-sm-6"
                style="text-align: center; margin-top: 2em"
              >
                <div v-if="article" class="card" style="margin: 0 auto">
                  <div class="card-body" style="text-align: center">
                    <h5 class="card-title" style="text-align: center">
                      <a @click="goArticle(article)"
                        ><img
                          :data-aos="index == 0 ? 'fade-right' : 'fade-left'"
                          :src="article.preview_img"
                          width="80%"
                          :alt="article.title"
                          class="aos-init"
                      /></a>
                    </h5>
                    <h1 class="article__maintitle">
                      <span
                        style="
                          background-color: #ffffff;
                          color: #333333;
                          font-size: 16px;
                        "
                        ><a
                          @click="goArticle(article)"
                          style="background-color: #ffffff; color: #333333"
                          ><strong
                            ><span
                              style="
                                font-family: 'LiHei Pro', 微軟正黑體;
                                background-color: #ffffff;
                              "
                              >{{ article.title }}</span
                            ></strong
                          ></a
                        ></span
                      >
                    </h1>
                    <p style="text-align: center"></p>
                    <div
                      style="
                        font-family: 'LiHei Pro', 微軟正黑體;
                        font-size: 14px;
                        white-space: break-spaces;
                      "
                      v-html="article.description"
                    ></div>

                    <ul class="actions">
                      <li>
                        <a @click="goArticle(article)" class="button">MORE</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </div>
      </div>
    </div>
    <div class="fcol fcol-3">
      <div class="container">
        <div id="nicedit_2" class="edit-area">
          <div class="row">
            <div class="col-sm-6">
              <div class="x31">
                <p class="x31x1">
                  <span style="font-size: 30px"
                    ><strong>創辦人介紹</strong></span
                  >
                </p>
                <p>
                  <span style="color: #ffffff"
                    ><span style="font-size: 20px; color: #000000"
                      ><br /><br />樓中亮&mdash;台灣中醫預防保健協會創會理事長，</span
                    ></span
                  ><span style="color: #ffffff; font-size: 20px"
                    ><span style="color: #000000"
                      >現任「樓中亮中醫體系」院長。</span
                    ></span
                  >
                </p>
                <p>
                  <span style="color: #ffffff; font-size: 20px"
                    ><span style="color: #000000"
                      >天訊國際控股集團創辦人。</span
                    ></span
                  >
                </p>
                <p>
                  <span style="color: #ffffff; font-size: 20px"
                    ><span style="color: #000000"
                      >南京中醫藥大學醫學博士。</span
                    ></span
                  >
                </p>
                <p>
                  <span style="font-size: 20px"
                    ><br />近年運用科技將「樓氏古中醫」與AI、大數據、影像辨識，整合成一套「治未病」的預防保健系統&mdash;樓易健康管理APP，希望能幫助更多人容易健康，健康更容易<br /><br />&nbsp;<br /><br
                  /></span>
                </p>
                <p>
                  <span
                    style="background-color: initial; font-size: 1rem"
                  ></span
                  ><a
                    @click="
                      $router.push({
                        name: 'BrandStory',
                      })
                    "
                    class="button"
                    style="font-size: 1rem"
                    >關於我們</a
                  >
                </p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="x32"><span></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fcol fcol-4">
      <div class="container">
        <div id="nicedit_3" class="edit-area"></div>
      </div>
    </div>
    <div class="fcol fcol-5">
      <div class="container">
        <div id="nicedit_4" class="edit-area">
          <div class="x4 x4x2">
            <div class="row rk_case" style="text-align: center">
              <div
                v-for="(article, index) in sixBottomArticles"
                :key="index"
                class="col-sm-4"
                style="text-align: left"
              >
                <div v-if="article">
                  <a @click="goArticle(article)"
                    ><div
                      class="img"
                      :alt="article.title"
                      style="
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                      "
                      :style="`background:url(${article.preview_img});                      
                      background-repeat: no-repeat;
                      background-position: center;
                      background-size: cover;`"
                  /></a>
                  <div class="x4x1">
                    <p
                      class="tw-data-text tw-text-large tw-ta"
                      data-placeholder="翻譯"
                      id="tw-target-text"
                      dir="ltr"
                    >
                      <span style="font-family: 'LiHei Pro', 微軟正黑體"
                        ><a @click="goArticle(article)"
                          ><strong>
                            <span
                              lang="zh-TW"
                              style="font-size: 18px"
                              tabindex="0"
                            >
                              <span style="color: #333333">{{
                                article.title
                              }}</span>
                            </span>
                          </strong>
                        </a>
                      </span>
                    </p>
                    <p style="text-align: left">
                      <span
                        style="
                          font-size: 14px;
                          font-family: 'LiHei Pro', 微軟正黑體;
                        "
                        >{{ article.description }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p></p>
          <p></p>
        </div>
      </div>
    </div>
    <!-- <div class="fcol fcol-6">
      <div class="container">
        <div id="nicedit_5" class="edit-area">
          <div class="x6">
            <div class="col-sm-6 offset-sm-4" style="padding: 0 2em">
              <div class="col-ms-12" style="text-align: center">
                <p style="text-align: left">
                  <span style="font-size: 26px"
                    ><strong>想要搶先擁有貼心的健康管理夥伴</strong></span
                  >
                </p>
                <p style="text-align: left">
                  <span style="font-size: 16px"
                    >隨著天氣時令提醒您健康注意事項</span
                  ><br /><span style="font-size: 16px"
                    >還能事先預測健康關卡，提早防範</span
                  >
                </p>
                <p style="text-align: left">
                  <span style="font-size: 16px"
                    >樓易建康管理APP，即將上市<br /><br
                  /></span>
                </p>
                <p style="text-align: left">
                  <span style="font-size: 16px"
                    ><strong>訂閱電子報&nbsp; 搶先獲得最新資訊</strong></span
                  >
                </p>
                <div class="row">
                  <div class="col-12">
                    <div id="mc_embed_signup">
                      <form
                        action="/louyiai.us4.list-manage.com/subscribe/post.htm"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        class="validate"
                        target="_blank"
                        novalidate=""
                      >
                        <div
                          id="mc_embed_signup_scroll"
                          style="text-align: left"
                        >
                          <label for="mce-EMAIL"></label
                          ><input
                            type="email"
                            value=""
                            name="EMAIL"
                            class="email"
                            id="mce-EMAIL"
                            placeholder="請輸入email"
                            required=""
                          />
                          &nbsp;<span
                            style="background-color: initial; font-size: 1rem"
                          ></span
                          ><input
                            type="submit"
                            style="
                              background-color: #0278ae;
                              width: 60px;
                              height: 35px;
                              border: 2px blue none;
                              color: white;
                            "
                            value="訂閱"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            class="button"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import ArticleApiHelper from "~/resources/js/script/Utility/ArticleApiHelper";
export default {
  beforeCreate() {
    if (this.$route.name != "Home") {
      this.$router.replace({ name: "Home" });
    }
  },
  mounted() {
    this.fetchSpecialArticles();
  },
  updated() {
    AOS.init();
  },
  data() {
    return {
      specialArticles: [],
    };
  },
  computed: {
    articles() {
      return this.$store.state.articles;
    },
    topTwoArticles() {
      const articles = [];
      let maxCount = 2;
      for (let i = 0; i < maxCount; i++) {
        articles.push(this.findArticle("home_top", i));
      }
      return articles;
    },
    sixBottomArticles() {
      const articles = [];
      let maxCount = 6;
      for (let i = 0; i < maxCount; i++) {
        articles.push(this.findArticle("home_bottom", i));
      }
      return articles;
    },
    loading() {
      return !this.articles.length || !this.specialArticles.length;
    },
  },
  methods: {
    findArticle(findPosition, findIndex) {
      let article = null;
      const indexInfo = this.specialArticles.find(
        (articleInfo) =>
          articleInfo.page_position == findPosition &&
          articleInfo.index == findIndex
      );
      if (indexInfo) {
        article = this.articles.find(
          (article) => article.id == indexInfo.article_id
        );
      }
      return article;
    },
    fetchSpecialArticles() {
      this.specialArticles = [];
      ArticleApiHelper.getHomeSetting()
        .then((result) => {
          this.specialArticles = result.data;
          loading(false);
        })
        .catch(errorHandler);
    },
    goArticle(article) {
      const map = {
        news: "NewsDetail",
        featured: "FeaturedDetail",
        beauty: "FeaturedDetail",
        healthy: "FeaturedDetail",
        video: "VideoDetail",
      };
      this.$router.push({
        name: map[article.category],
        params: {
          id: article.id,
          article,
        },
      });
    },
  },
};
</script>