<template>
  <div>
    <div class="container">
      <div class="footer-edit">
        <div id="nicedit_6" class="edit-area">
          <div class="f1">
            <div class="col-sm-4">
              <span class="f1x1" style="font-size: 20px; line-height: 2em"
                >友好連結</span
              ><br /><span class="f1x3" style="color: #ffffff"
                ><a :href="tianxun_group_link" style="color: #ffffff"
                  >天訊國際</a
                ><br /><a :href="shanghai_link" style="color: #ffffff"
                  >上海樓易</a
                ><br /><a
                  href="http://lous.ee-health.com.tw"
                  style="color: #ffffff"
                  >樓中亮中醫預防保健網</a
                >
              </span>
            </div>
            <div class="col-sm-4">
              <span class="f1x1" style="font-size: 20px; line-height: 2em"
                >訂閱最新健康資訊</span
              ><br /><span class="f1x3" style="color: #ffffff"
                ><a
                  href="https://www.facebook.com/eehealth"
                  style="color: #ffffff"
                  ><img
                    src="/static.iyp.tw/40147/files/96fc57b3-c60d-43d7-befd-6cb86ec10b24.png"
                    width="15"
                    height="15"
                    alt=""
                  />樓中亮中醫博士</a
                ><br /><a
                  href="https://vt.tiktok.com/Fw9SNe/"
                  style="color: #ffffff"
                  ><img
                    src="/static.iyp.tw/40147/files/6bd9e4ee-be92-4012-b39d-436640203f41.png"
                    width="15"
                    height="15"
                    alt=""
                  />樓中亮醫師Tiktok</a
                ><br /><a
                  href="https://www.instagram.com/louyi_health/?hl=zh-tw"
                  style="color: #ffffff"
                  ><img
                    src="/static.iyp.tw/40147/files/849f8f45-e1c6-4af9-b894-901f7503273d.png"
                    width="15"
                    height="15"
                    alt=""
                  />樓中亮醫師</a
                ><br /><a
                  href="https://www.youtube.com/user/loueehealth"
                  style="color: #ffffff"
                  ><img
                    src="/static.iyp.tw/40147/files/a1052bb6-b633-49a6-be14-f25a9c350a5e.png"
                    width="15"
                    height="15"
                    alt=""
                  />樓中亮</a
                ></span
              >
            </div>
            <div class="col-sm-4">
              <span class="f1x1" style="font-size: 20px; line-height: 2em"
                >聯絡我們</span
              ><br /><span class="f1x3" style="color: #ffffff"
                >tel : (04)2258-3089</span
              >
            </div>
            <div class="col-sm-4">
              <span class="f1x3" style="color: #ffffff"></span>
            </div>
            <div class="col-sm-4">
              <span class="f1x3" style="color: #ffffff"
                >mail : cs@louyiai.com</span
              >
            </div>
            <div class="col-sm-12" style="text-align: center">
              <div class="m-2" style="text-align: center">
                <div id="mc_embed_signup_scroll" style="display: inline-flex">
                  <input
                    v-model="email"
                    type="email"
                    name="EMAIL"
                    placeholder="請輸入email"
                    required="required"
                    class="email"
                  /><input
                    type="submit"
                    value="訂閱"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="button mx-2"
                    style="
                      background-color: rgb(79, 79, 79);
                      width: 60px;
                      height: 35px;
                      border: 2px none blue;
                      color: white;
                    "
                    @click="subscribe()"
                  />
                </div>
              </div>
              <span class="f1x2"
                >Copyright &copy; 2020 香港商天訊國際控股集團 - All Rights
                Reserved.</span
              >
            </div>
          </div>

          <div class="col-sm-12" style="text-align: center"></div>
          <p></p>

          <div class="f4" style="text-align: center">
            <div
              class="a2a_kit a2a_kit_size_32 a2a_default_style"
              style="line-height: 32px"
            >
              <a
                class="a2a_dd"
                href="https://www.addtoany.com/share.htm#url=https%3A%2F%2F19tpc01326hqdy.iyp.tw%2Fadmin%2Fdesign%2Fproduct-detail-3095729.html&amp;title=%E5%85%AD%E4%B9%8B%E6%B0%A3%E5%A5%BD%E7%99%BC%E7%96%BE%E7%97%85%E8%88%87%E8%AA%BF%E9%A4%8A%E5%BB%BA%E8%AD%B0%20-%20%E6%A8%93%E6%98%93%E5%81%A5%E5%BA%B7%E7%A7%91%E6%8A%80%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8"
                ><span
                  class="a2a_svg a2a_s__default a2a_s_a2a"
                  style="background-color: #0166ff"
                  ><svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewbox="0 0 32 32"
                  >
                    <g fill="#FFF">
                      <path d="M14 7h4v18h-4z"></path>
                      <path d="M7 14h18v4H7z"></path>
                    </g></svg></span
                ><span
                  class="a2a_label a2a_localize"
                  data-a2a-localize="inner,Share"
                  >分享</span
                ></a
              ><a
                class="a2a_button_facebook"
                target="_blank"
                href="#facebook"
                rel="nofollow noopener"
                ><span
                  class="a2a_svg a2a_s__default a2a_s_facebook"
                  style="background-color: #3b5998"
                  ><svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewbox="0 0 32 32"
                  >
                    <path
                      fill="#FFF"
                      d="M17.78 27.5V17.008h3.522l.527-4.09h-4.05v-2.61c0-1.182.33-1.99 2.023-1.99h2.166V4.66c-.375-.05-1.66-.16-3.155-.16-3.123 0-5.26 1.905-5.26 5.405v3.016h-3.53v4.09h3.53V27.5h4.223z"
                    ></path></svg></span
                ><span class="a2a_label">Facebook</span></a
              ><a
                class="a2a_button_line"
                target="_blank"
                href="#line"
                rel="nofollow noopener"
                ><span
                  class="a2a_svg a2a_s__default a2a_s_line"
                  style="background-color: #00c300"
                  ><svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewbox="0 0 32 32"
                  >
                    <path
                      fill="#FFF"
                      d="M28 14.304c0-5.37-5.384-9.738-12-9.738S4 8.936 4 14.304c0 4.814 4.27 8.846 10.035 9.608.39.084.923.258 1.058.592.122.303.08.778.04 1.084l-.172 1.028c-.05.303-.24 1.187 1.04.647s6.91-4.07 9.43-6.968c1.74-1.905 2.57-3.842 2.57-5.99zM11.302 17.5H8.918a.631.631 0 0 1-.63-.63V12.1a.63.63 0 0 1 1.26.002v4.14h1.754c.35 0 .63.28.63.628a.63.63 0 0 1-.63.63zm2.467-.63a.631.631 0 0 1-.63.628.629.629 0 0 1-.63-.63V12.1a.63.63 0 1 1 1.26 0v4.77zm5.74 0a.632.632 0 0 1-1.137.378l-2.443-3.33v2.95a.631.631 0 0 1-1.26 0V12.1a.634.634 0 0 1 .63-.63.63.63 0 0 1 .504.252l2.444 3.328V12.1a.63.63 0 0 1 1.26 0v4.77zm3.853-3.014a.63.63 0 1 1 0 1.258H21.61v1.126h1.755a.63.63 0 1 1 0 1.258H20.98a.63.63 0 0 1-.628-.63V12.1a.63.63 0 0 1 .63-.628h2.384a.63.63 0 0 1 0 1.258h-1.754v1.126h1.754z"
                    ></path></svg></span
                ><span class="a2a_label">Line</span></a
              ><a
                class="a2a_button_wechat"
                target="_blank"
                href="#wechat"
                rel="nofollow noopener"
                ><span
                  class="a2a_svg a2a_s__default a2a_s_wechat"
                  style="background-color: #7bb32e"
                  ><svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewbox="0 0 32 32"
                  >
                    <g fill="#FFF">
                      <path
                        d="M20.674 12.458c-2.228.116-4.165.792-5.738 2.318-1.59 1.542-2.315 3.43-2.116 5.772-.87-.108-1.664-.227-2.462-.294-.276-.023-.602.01-.836.14-.774.438-1.517.932-2.397 1.482.16-.73.266-1.37.45-1.985.137-.45.074-.7-.342-.994-2.673-1.89-3.803-4.714-2.958-7.624.78-2.69 2.697-4.323 5.302-5.173 3.555-1.16 7.55.022 9.712 2.845a6.632 6.632 0 0 1 1.38 3.516zm-10.253-.906c.025-.532-.44-1.01-.984-1.027a.997.997 0 0 0-1.038.964.984.984 0 0 0 .977 1.02 1.017 1.017 0 0 0 1.05-.96zm5.35-1.028c-.55.01-1.01.478-1 1.012.01.554.466.987 1.03.98a.982.982 0 0 0 .99-1.01.992.992 0 0 0-1.02-.982z"
                      ></path>
                      <path
                        d="M25.68 26.347c-.705-.314-1.352-.785-2.042-.857-.686-.072-1.408.324-2.126.398-2.187.224-4.147-.386-5.762-1.88-3.073-2.842-2.634-7.2.92-9.53 3.16-2.07 7.795-1.38 10.022 1.493 1.944 2.51 1.716 5.837-.658 7.94-.687.61-.934 1.11-.493 1.917.086.148.095.336.14.523zm-8.03-7.775c.448 0 .818-.35.835-.795a.835.835 0 0 0-.83-.865.845.845 0 0 0-.84.86c.016.442.388.8.834.8zm5.176-1.658a.83.83 0 0 0-.824.794c-.02.47.347.858.813.86.45 0 .807-.34.823-.79a.825.825 0 0 0-.812-.864z"
                      ></path>
                    </g></svg></span
                ><span class="a2a_label">WeChat</span></a
              ><a
                class="a2a_button_sina_weibo"
                target="_blank"
                href="#sina_weibo"
                rel="nofollow noopener"
                ><span
                  class="a2a_svg a2a_s__default a2a_s_sina_weibo"
                  style="background-color: #e6162d"
                  ><svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewbox="0 0 32 32"
                  >
                    <g fill="#FFF">
                      <path
                        d="M13.92 24.99c-4.303.424-8.02-1.52-8.3-4.346-.278-2.827 2.987-5.463 7.292-5.888 4.304-.426 8.018 1.52 8.297 4.345.274 2.83-2.987 5.468-7.29 5.89m8.61-9.38c-.367-.11-.62-.184-.428-.663.416-1.046.458-1.946.01-2.59-.846-1.204-3.155-1.14-5.8-.03 0-.004-.834.362-.62-.297.406-1.31.345-2.407-.29-3.04-1.435-1.437-5.255.055-8.53 3.33C4.422 14.77 3 17.37 3 19.617c0 4.3 5.513 6.913 10.907 6.913 7.07 0 11.776-4.105 11.776-7.37 0-1.97-1.66-3.09-3.15-3.55m4.693-7.87a6.89 6.89 0 0 0-6.55-2.12h-.002a.997.997 0 0 0-.765 1.182.99.99 0 0 0 1.18.765 4.91 4.91 0 0 1 4.66 1.508 4.899 4.899 0 0 1 1.02 4.787.995.995 0 1 0 1.894.615v-.004a6.883 6.883 0 0 0-1.44-6.732m-2.622 2.37a3.343 3.343 0 0 0-3.192-1.03.852.852 0 0 0-.655 1.016.854.854 0 0 0 1.016.657v.003a1.655 1.655 0 0 1 1.564.502c.406.453.514 1.066.338 1.606h.005a.858.858 0 1 0 1.63.528 3.345 3.345 0 0 0-.7-3.28"
                      ></path>
                      <path
                        d="M14.16 19.87c-.15.26-.484.383-.746.275-.256-.104-.335-.393-.19-.646.15-.255.47-.378.725-.276.26.094.35.386.21.644m-1.375 1.76c-.417.666-1.308.957-1.98.65-.66-.302-.855-1.072-.44-1.72.413-.645 1.274-.933 1.94-.653.673.287.888 1.054.48 1.724m1.564-4.7c-2.047-.533-4.364.49-5.254 2.293-.904 1.84-.028 3.884 2.04 4.552 2.144.69 4.67-.368 5.55-2.354.865-1.943-.216-3.943-2.336-4.49"
                      ></path>
                    </g></svg></span
                ><span class="a2a_label">Sina Weibo</span></a
              ><a
                class="a2a_button_linkedin"
                target="_blank"
                href="#linkedin"
                rel="nofollow noopener"
                ><span
                  class="a2a_svg a2a_s__default a2a_s_linkedin"
                  style="background-color: #007bb5"
                  ><svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewbox="0 0 32 32"
                  >
                    <path
                      d="M6.227 12.61h4.19v13.48h-4.19V12.61zm2.095-6.7a2.43 2.43 0 0 1 0 4.86c-1.344 0-2.428-1.09-2.428-2.43s1.084-2.43 2.428-2.43m4.72 6.7h4.02v1.84h.058c.56-1.058 1.927-2.176 3.965-2.176 4.238 0 5.02 2.792 5.02 6.42v7.395h-4.183v-6.56c0-1.564-.03-3.574-2.178-3.574-2.18 0-2.514 1.7-2.514 3.46v6.668h-4.187V12.61z"
                      fill="#FFF"
                    ></path></svg></span
                ><span class="a2a_label">LinkedIn</span></a
              ><a
                class="a2a_button_telegram"
                target="_blank"
                href="#telegram"
                rel="nofollow noopener"
                ><span
                  class="a2a_svg a2a_s__default a2a_s_telegram"
                  style="background-color: #2ca5e0"
                  ><svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewbox="0 0 32 32"
                  >
                    <path
                      fill="#FFF"
                      d="M25.515 6.896L6.027 14.41c-1.33.534-1.322 1.276-.243 1.606l5 1.56 1.72 5.66c.226.625.115.873.77.873.506 0 .73-.235 1.012-.51l2.43-2.363 5.056 3.734c.93.514 1.602.25 1.834-.863l3.32-15.638c.338-1.363-.52-1.98-1.41-1.577z"
                    ></path></svg></span
                ><span class="a2a_label">Telegram</span></a
              ><a
                class="a2a_button_twitter"
                target="_blank"
                href="#twitter"
                rel="nofollow noopener"
                ><span
                  class="a2a_svg a2a_s__default a2a_s_twitter"
                  style="background-color: #55acee"
                  ><svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewbox="0 0 32 32"
                  >
                    <path
                      fill="#FFF"
                      d="M28 8.557a9.913 9.913 0 0 1-2.828.775 4.93 4.93 0 0 0 2.166-2.725 9.738 9.738 0 0 1-3.13 1.194 4.92 4.92 0 0 0-3.593-1.55 4.924 4.924 0 0 0-4.794 6.049c-4.09-.21-7.72-2.17-10.15-5.15a4.942 4.942 0 0 0-.665 2.477c0 1.71.87 3.214 2.19 4.1a4.968 4.968 0 0 1-2.23-.616v.06c0 2.39 1.7 4.38 3.952 4.83-.414.115-.85.174-1.297.174-.318 0-.626-.03-.928-.086a4.935 4.935 0 0 0 4.6 3.42 9.893 9.893 0 0 1-6.114 2.107c-.398 0-.79-.023-1.175-.068a13.953 13.953 0 0 0 7.55 2.213c9.056 0 14.01-7.507 14.01-14.013 0-.213-.005-.426-.015-.637.96-.695 1.795-1.56 2.455-2.55z"
                    ></path></svg></span
                ><span class="a2a_label">Twitter</span></a
              ><a
                class="a2a_button_email"
                target="_blank"
                href="#email"
                rel="nofollow noopener"
                ><span
                  class="a2a_svg a2a_s__default a2a_s_email"
                  style="background-color: #0166ff"
                  ><svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewbox="0 0 32 32"
                  >
                    <path
                      fill="#FFF"
                      d="M26 21.25v-9s-9.1 6.35-9.984 6.68C15.144 18.616 6 12.25 6 12.25v9c0 1.25.266 1.5 1.5 1.5h17c1.266 0 1.5-.22 1.5-1.5zm-.015-10.765c0-.91-.265-1.235-1.485-1.235h-17c-1.255 0-1.5.39-1.5 1.3l.015.14s9.035 6.22 10 6.56c1.02-.395 9.985-6.7 9.985-6.7l-.015-.065z"
                    ></path></svg></span
                ><span class="a2a_label">Email</span></a
              ><a
                class="a2a_button_copy_link"
                target="_blank"
                href="#copy_link"
                rel="nofollow noopener"
                ><span
                  class="a2a_svg a2a_s__default a2a_s_link"
                  style="background-color: #0166ff"
                  ><svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewbox="0 0 32 32"
                  >
                    <path
                      fill="#FFF"
                      d="M24.412 21.177c0-.36-.126-.665-.377-.917l-2.804-2.804a1.235 1.235 0 0 0-.913-.378c-.377 0-.7.144-.97.43.026.028.11.11.255.25.144.14.24.236.29.29s.117.14.2.256c.087.117.146.232.177.344.03.112.046.236.046.37 0 .36-.126.666-.377.918a1.25 1.25 0 0 1-.918.377 1.4 1.4 0 0 1-.373-.047 1.062 1.062 0 0 1-.345-.175 2.268 2.268 0 0 1-.256-.2 6.815 6.815 0 0 1-.29-.29c-.14-.142-.223-.23-.25-.254-.297.28-.445.607-.445.984 0 .36.126.664.377.916l2.778 2.79c.243.243.548.364.917.364.36 0 .665-.118.917-.35l1.982-1.97c.252-.25.378-.55.378-.9zm-9.477-9.504c0-.36-.126-.665-.377-.917l-2.777-2.79a1.235 1.235 0 0 0-.913-.378c-.35 0-.656.12-.917.364L7.967 9.92c-.254.252-.38.553-.38.903 0 .36.126.665.38.917l2.802 2.804c.242.243.547.364.916.364.377 0 .7-.14.97-.418-.026-.027-.11-.11-.255-.25s-.24-.235-.29-.29a2.675 2.675 0 0 1-.2-.255 1.052 1.052 0 0 1-.176-.344 1.396 1.396 0 0 1-.047-.37c0-.36.126-.662.377-.914.252-.252.557-.377.917-.377.136 0 .26.015.37.046.114.03.23.09.346.175.117.085.202.153.256.2.054.05.15.148.29.29.14.146.222.23.25.258.294-.278.442-.606.442-.983zM27 21.177c0 1.078-.382 1.99-1.146 2.736l-1.982 1.968c-.745.75-1.658 1.12-2.736 1.12-1.087 0-2.004-.38-2.75-1.143l-2.777-2.79c-.75-.747-1.12-1.66-1.12-2.737 0-1.106.392-2.046 1.183-2.818l-1.186-1.185c-.774.79-1.708 1.186-2.805 1.186-1.078 0-1.995-.376-2.75-1.13l-2.803-2.81C5.377 12.82 5 11.903 5 10.826c0-1.08.382-1.993 1.146-2.738L8.128 6.12C8.873 5.372 9.785 5 10.864 5c1.087 0 2.004.382 2.75 1.146l2.777 2.79c.75.747 1.12 1.66 1.12 2.737 0 1.105-.392 2.045-1.183 2.817l1.186 1.186c.774-.79 1.708-1.186 2.805-1.186 1.078 0 1.995.377 2.75 1.132l2.804 2.804c.754.755 1.13 1.672 1.13 2.75z"
                    ></path></svg></span
                ><span class="a2a_label">Copy Link</span></a
              >
            </div>
          </div>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OtherApiHelper from "~/resources/js/script/Utility/OtherApiHelper";
export default {
  data() {
    return { email: "" };
  },
  computed: {
    tianxun_group_link() {
      return process.env.MIX_TIANXUN_GROUP_LINK;
    },
    shanghai_link() {
      return process.env.MIX_SHANGHAI_LINK;
    },
  },
  methods: {
    subscribe() {
      loading();
      OtherApiHelper.subscribe(this.email)
        .then(() => {
          alert("訂閱成功");
          loading(false);
        })
        .catch(errorHandler);
    },
  },
};
</script>