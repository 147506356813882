/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import 'bootstrap';
import './script/Common.js';
import router from './router';
import Vue from 'vue';
import store from "./store";
import ViewUI from 'view-design';
import VueLazyload from 'vue-lazyload';
import 'view-design/dist/styles/iview.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/app.scss';
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.use(ViewUI);
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: '/img/error.png',
    loading: '/img/loading.gif',
    attempt: 1,
    lazyComponent: true
});


const app = new Vue({
    router,
    el: '#app',
    store
});