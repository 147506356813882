<template>
  <div id="main-wrap" class="main-container productdetail richScope">
    <div class="fcol fcol-header">
      <div class="container">
        <ol id="breadcrumb">
          <li><a href="/index.html" class="home">回首頁</a>&nbsp;>&nbsp;</li>
          <li><a href="featured-articles.html">精選文章</a> ></li>
          <li>{{ article.title }}</li>
        </ol>
        <div class="h1title">
          <h1>
            <span>{{ article.title }}</span>
          </h1>
        </div>
      </div>
    </div>
    <div class="fcol fcol-1">
      <div class="container">
        <div class="main side-height">
          <div
            class="detail-img popup-gallery lightbox"
            id="magnifier"
            data-enable="0"
          >
            <div class="main-image-area position-relative">
              <a :title="article.title" :href="article.img"
                ><img
                  :alt="article.title"
                  :src="article.img"
                  class="photoBig"/></a
              ><span class="zoominfo"
                ><i class="fa fa-search-plus"></i> 點擊圖片放大</span
              >
            </div>
          </div>
          <div class="detail-spec">
            <b>名稱:</b>
            <h2>
              <div id="productName" class="product-content edit-area">
                <p>{{ article.title }}</p>
              </div>
            </h2>
            <b>介紹:</b>
            <div id="productSpec" class="product-content edit-area">
              <p>
                {{ article.description }}
              </p>
            </div>
          </div>
          <div class="detail-desc">
            <b>詳細介紹:</b>
          </div>
          <div
            id="productDesc"
            class="product-content edit-area"
            v-html="article.content"
          ></div>
          <div class="related-product">
            <h3><span>更多內容</span></h3>
            <slot name="more"> </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    article: {
      type: Object,
      default() {
        return {
          title: "",
          img: "",
          content: "",
          description: "",
        };
      },
    },
  },
};
</script>