import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";
const api = {
    "subscribe": "/api/Subscribe"
}



export default class OtherApiHelper extends ApiHelper{
    public static subscribe(email): Promise<any> {
        return this.callSelf(ApiMethods.POST, `${api.subscribe}/${email}`);
    }
}