<template>
  <div id="main-wrap" class="main-container">
    <div class="fcol fcol-header">
      <div class="container">
        <ol id="breadcrumb"></ol>
        <div class="h1title">
          <h1><span>精彩影片</span></h1>
        </div>
      </div>
    </div>
    <div class="fcol fcol-1">
      <div class="container">
        <div id="product-header" class="edit-area main side-height">
          <p style="text-align: left">
            <span style="font-size: 40px"
              ><img
                src="/static.iyp.tw/40147/files/9361a4a2-40a7-4db7-9ebe-cfe438daa639.jpg"
                width="100%"
                alt="" /><br /><br /></span
            ><span style="font-size: 40px"
              ><strong
                ><span style="font-size: 30px">精彩影片</span></strong
              ></span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="fcol fcol-2">
      <div class="container">
        <div class="main side-height">
          <ul class="product-list arrange_3">
            <li v-for="article in articles" :key="article.id">
              <ArticleItem
                :article="article"
                @click.native="
                  $router.push({
                    name: 'VideoDetail',
                    params: { id: article.id, article },
                  })
                "
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fcol fcol-3">
      <div class="container">
        <div id="product-footer" class="edit-area"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  beforeCreate() {},
  computed: {
    articles() {
      return this.$store.state.articles.filter(
        (article) => article.category == "other" && article.type == "video"
      );
    },
  },
  watch: {},
};
</script>