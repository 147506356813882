<template>
  <div>
    <div class="rwd-wrapper">
      <div id="wrap" class="">
        <div id="main-wrap" class="main-container">
          <div class="fcol fcol-header">
            <div class="container">
              <ol id="breadcrumb"></ol>
              <div class="h1title">
                <h1><span>精選文章</span></h1>
              </div>
            </div>
          </div>
          <div class="fcol fcol-1">
            <div class="container">
              <div id="product-header" class="edit-area main side-height">
                <p style="text-align: left">
                  <span style="font-size: 40px"
                    ><img
                      src="/static.iyp.tw/40147/files/817449c2-11d4-4e69-8fb6-492b887b2a2b.jpg"
                      width="100%"
                      alt="" /><br /><br /></span
                  ><span style="font-size: 40px"
                    ><strong
                      ><span style="font-size: 30px">精</span></strong
                    ></span
                  ><strong><span style="font-size: 30px">選文章</span></strong>
                </p>
              </div>
            </div>
          </div>
          <div class="fcol fcol-2">
            <div class="container">
              <div class="main side-height">
                <ul class="product-list arrange_3">
                  <li v-for="article in articles" :key="article.id">
                    <ArticleItem
                      :article="article"
                      @click.native="
                        $router.push({
                          name: 'FeaturedDetail',
                          params: { id: article.id, article },
                        })
                      "
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="fcol fcol-3">
            <div class="container">
              <div id="product-footer" class="edit-area"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar navbar-fixed-top">
      <div class="container">
        <button type="button" class="rwd-navbar-toggle rwd-collapse">
          <span class="label">選單</span><span class="navbar-icon-bar"></span>
        </button>
        <ul class="nav navbar-nav navbar-right" id="topnav">
          <li class="hidden-xs home"><a href="/index.html">回首頁</a></li>
          <li id="language" class="dropdown hidden-xs">
            <a
              href="featured-articles.html#"
              class="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-expanded="false"
            >
              Language / 語系 <span class="caret"></span
            ></a>
            <ul class="dropdown-menu" role="menu"></ul>
          </li>
        </ul>
      </div>
    </div>
    <div id="rwd-side" class="rwd-side rwd-collapse">
      <div class="inner">
        <ul class="topnav"></ul>
        <div class="nav"></div>
        <div class="side"></div>
      </div>
    </div>
    <div id="product-search-modal" class="modal fade">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="psm-title">搜尋</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div id="product-search-main">
                <input
                  type="text"
                  class="product-keyword"
                  value=""
                  autocomplete="off"
                /><button
                  type="submit"
                  data-loading-text="Search.."
                  class="btn-search"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <h4 id="product-serach-msg"></h4>
            </div>
            <div class="hidden text-center" id="product-serach-loading">
              <img src="/templates/images/loading.gif" alt="searching-icon" />
            </div>
            <div class="row">
              <div class="col-md-4 media-list">
                <div class="row">
                  <div class="col-md-12">
                    <div id="product-search-cates" class="list-group"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-8 media-list">
                <ul id="product-search-result"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FIX MENU BUTTON -->

    <head>
      <link href="/fonts.googleapis.com/icon.css" rel="stylesheet" />
      <link href="/fonts.googleapis.com/css_2.css" rel="stylesheet" />
    </head>

    <button class="closed" id="newMenuButton" onclick="onMenuButtonClick()">
      <i class="material-icons">menu</i>
      <span>選單</span>
    </button>
  </div>
</template>
<script>
export default {
  computed: {
    articles() {
      const categories = ["featured", "beauty", "healthy"];
      return this.$store.state.articles.filter((article) => {
        return categories.find((category) => article.category == category);
      });
    },
  },
  watch: {},
};
</script>
