<template>
  <ArticleCard :article="article">
    <ul slot="more" class="product-list">
      <li v-for="article in moreArticles" :key="article.id">
        <ArticleItem
          :article="article"
          @click.native="
            $router.push({
              name: 'FeaturedDetail',
              params: { id: article.id, article },
            })
          "
        />
      </li>
    </ul>
  </ArticleCard>
</template>
<script>
import ArticlePageBase from "./ArticlePageBase.vue";
export default {
  extends: ArticlePageBase,
  data() {
    return {
      category: "featured,healthy,beauty",
    };
  },
};
</script>