<template>
  <div class="news">
    <div class="fcol fcol-header">
      <div class="container">
        <ol id="breadcrumb"></ol>
        <div class="h1title">
          <h1><span>最新消息</span></h1>
        </div>
      </div>
    </div>

    <div class="fcol fcol-1">
      <div class="container" style="overflow-wrap: break-word">
        <div id="news-heading">
          <div class="news-box" style="display: flex">
            <h3
              id="newsTitle"
              class="news-content edit-area"
              style="flex-grow: 1"
            >
              <span style="font-size: 20px; color: #333333">{{
                article.title
              }}</span>
            </h3>
            <span class="date" style="margin: auto">{{
              article.timestamp.split(" ")[0]
            }}</span>
          </div>
        </div>
        <div v-html="article.content"></div>
      </div>
    </div>
    <div class="fcol fcol-2">
      <div class="container">
        <div class="news-head">
          <span class="tit">標 題</span><span class="date">日 期</span>
        </div>
        <div class="news-list">
          <ul>
            <li v-for="news in moreNews" :key="news.id">
              <a
                @click="
                  $router.push({
                    name: 'NewsDetail',
                    params: { id: news.id },
                  })
                "
                ><span class="ico"></span>
                <h3>
                  <span style="font-size: 20px; color: #333333">{{
                    news.title
                  }}</span>
                </h3>
                <span class="date">{{ news.timestamp.split(" ")[0] }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String | Number,
      default: null,
    },
  },
  beforeCreate() {

  },
  computed: {
    category() {
      return "news";
    },
    article() {
      let item = {
        content: "",
        img: "",
        category: "",
        timestamp: "",
      };
      if (this.id) {
        item =
          this.$store.state.articles.find(
            (article) =>
              article.category == this.category && article.id == this.id
          ) ?? item;
      }
      if (!item.id) {
        item = this.articles[0] ?? item;
      }
      return item;
    },
    articles() {
      return this.$store.state.articles.filter(
        (article) => article.category == this.category
      );
    },
    moreNews() {
      let maxCount = 5;
      maxCount =
        this.articles.length < maxCount ? this.articles.length : maxCount;
      let more = [];
      const index = this.articles.findIndex(
        (article) => article.id == this.article.id
      );
      more = this.articles.slice(index + 1, index + 1 + maxCount);
      const realCount = more.length;
      if (realCount < maxCount) {
        more.push(...this.articles.slice(0, maxCount - realCount));
      }
      return more.filter((m) => m.id != this.article.id);
    },
  },
  watch: {

  },
};
</script>