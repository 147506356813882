import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";
const api = {
    "article": "/api/Article",
    "page": "/api/Page",
    "homeSetting": "/api/HomeSetting"
}



export default class ArticleApiHelper extends ApiHelper {
    public static getArticle(id = null): Promise<any> {
        if (id) {
            return this.callSelf(ApiMethods.GET, `${api.article}/${id}`);
        } else {
            return this.callSelf(ApiMethods.GET, `${api.article}`);
        }
    }
    public static getPage(): Promise<any> {
        return this.callSelf(ApiMethods.GET, `${api.page}`);
    }
    public static getHomeSetting(): Promise<any> {
        return this.callSelf(ApiMethods.GET, `${api.homeSetting}`);
    }
}